import React from 'react';
import { getStoredLanguage, LocalizationContext } from '../LocalizationContext/LocalizationContext';
import { Language } from '../../translations';
import Flag from './Flag';
import { isMobile } from 'react-device-detect';

export enum Page {
    home = 'home',
    superMole = 'super-mole',
    privacy = 'privacy',
    about = 'about',
    energy = 'energy',
}

// export type page = Page.home | Page.superMole | Page.privacy | Page.about;

interface State {
    activeItem: Page|null,
    language: Language,
}

interface Props {
    onSelectItem: any,
}

export default class Menu extends React.Component<Props, State>
{
    state: State = {
        activeItem: null,
        language: getStoredLanguage(),
    }

    static contextType = LocalizationContext;

    componentDidMount() {
        this.updateFromHash();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.state.activeItem === null) {
            this.updateFromHash();
            return;
        }

        if (this.state.activeItem !== prevState.activeItem) {
            this.props.onSelectItem(this.state.activeItem);
        }
    }

    updateFromHash() {
        const hash = window.location.hash.substr(1);
        if (Object.values(Page).includes(hash as Page)) {
            this.setState({
                activeItem: hash as Page,
            });
        } else {
            this.setState({
                activeItem: Page.home,
            });
        }
    }

    setActiveItem = (item: Page) => {
        this.setState({
            activeItem: item,
        });
    }

    changeLanguage = (lang: Language) => {
        if (this.state.language !== lang) {
            this.context.setAppLanguage(lang);
            this.setState({
                language: lang
            });
        }
    }

    render() {
        const {activeItem, language} = this.state;
        const translations = this.context.t;

        return (
            <div className={isMobile ? 'mobile' : ''}>
                <ul id="menu">
                    <MenuItem menu={this} title={translations.menu.home} page={Page.home} isActive={activeItem === Page.home}/>
                    <MenuItem menu={this} title={translations.menu.superMole} page={Page.superMole} isActive={activeItem === Page.superMole}/>
                    <MenuItem menu={this} title={translations.menu.energy} page={Page.energy}  isActive={activeItem === Page.energy}/>
                    <MenuItem menu={this} title={translations.menu.privacy} page={Page.privacy} isActive={activeItem === Page.privacy}/>
                    <MenuItem menu={this} title={translations.menu.about} page={Page.about} isLast={true} isActive={activeItem === Page.about}/>
                </ul>
                <Flag lang={Language.eng} onPress={this.changeLanguage} activeLang={language}/>
                <Flag lang={Language.est} onPress={this.changeLanguage} activeLang={language}/>
                <div className={'langSelect usa'} onClick={() => this.changeLanguage(Language.eng)}/>
            </div>
        );
    }
}

interface ItemProps {
    title: string,
    isLast?: boolean,
    page: Page,
    menu: Menu,
    isActive: boolean,
}

class MenuItem extends React.Component<ItemProps> {
    onClick = () => {
        const {menu, page} = this.props;
        menu.setActiveItem(page);
    }

    render() {
        const {title, isLast, page, isActive} = this.props;

        const className = isActive ? 'active' : '';
        const listClass = isLast ? 'last menu-item' : 'menu-item';
        const href = page === 'home' ? '#' : '#' + page;

        return (
            <a href={href} className={className.trim()} onClick={this.onClick}>
                <li className={listClass}>
                    {title}
                </li>
            </a>
        );
    }
}