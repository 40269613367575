import './App.css';
import Menu, { Page } from './react-components/Menu/Menu';
import React from 'react';
import HomeScreen from './screens/HomeScreen';
import AboutScreen from './screens/AboutScreen';
import PrivacyScreen from './screens/PrivacyScreen';
import SuperMoleScreen from './screens/SuperMoleScreen';
import { LocalizationProvider } from './react-components/LocalizationContext/LocalizationContext';
import EnergyScreen from 'screens/EnergyScreen';

interface State {
    activeMenuItem: Page
}

export default class App extends React.Component<any, State>
{
    state: State = {
        activeMenuItem: Page.home,
    }

    selectMenuItem = (page: Page) => {
        this.setState({
            activeMenuItem: page,
        })
    }

    render() {
        let content = <div />;

        switch (this.state.activeMenuItem) {
            case Page.home:
                content = <HomeScreen />;
                break;
            case Page.superMole:
                content = <SuperMoleScreen />;
                break;
            case Page.privacy:
                content = <PrivacyScreen />;
                break;
            case Page.about:
                content = <AboutScreen />;
                break;
            case Page.energy:
                content = <EnergyScreen />;
                break;
        }

        return (
            <LocalizationProvider>
                <div className="App">
                    <div className="col-2">
                        <Menu onSelectItem={this.selectMenuItem}/>
                    </div>
                    <div className="col-8">
                        {content}
                    </div>
                </div>
            </LocalizationProvider>
        );
    }
}